<template>
<div class="category-verify">
  <Table border :columns="columns" :loading="loading" :data="data"></Table>
  <div class="page">
    <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize"  :current="page" show-total show-sizer show-elevator></Page>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "categoryVerify.vue",
  data(){
    return{
      columns: [
        {
          title: this.$t('dataset_mygroup_table_index'),
          key:'id',
          render: (h, para) => {
            return h('span', para.row.id );
          }
        },
        {
          title: this.$t('dataset_mygroup_table_title'),
          key: 'title',
          render: (h, para) => {
            return h('span', para.row.name );
          }
        },
        // {
        //   title: '状态',
        //   key: 'status',
        //   render: (h, para) => {
        //     return h('span', this.statuses[para.row.status] );
        //   }
        // },
        {
          title: this.$t('dataset_mygroup_table_data_count'),
          key: 'title',
          width:80,
          render: (h, para) => {
            return h('span', para.row.data_count );
          }
        },
        {
          title: '创建人',
          key: 'title',
          render: (h, para) => {
            return h('span', para.row.user && para.row.user.nickname + '('+ para.row.user.id + ')');
          }
        },
        {
          title: '状态',
          key: 'status',
          render: (h, para) => {
            return h('span', this.statuses[para.row.status]);
          }
        },
        {
          title: '审核状态',
          key: 'status',
          render: (h, para) => {
            return h('span', this.verifyStatus[para.row.verify_status]);
          }
        },
        {
          title: this.$t('dataset_mygroup_table_created_at'),
          key: 'created_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '更新时间',
          key: 'created_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('dataset_mygroup_table_op'),
          maxWidth:250,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.allData(params.row)
                  }
                }
              }, '题目'),
              params.row.verify_status == 0 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.verifyCategory(params.row,1)
                  }
                }
              }, '通过') : '',
              params.row.verify_status == 0 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.verifyCategory(params.row,2)
                  }
                }
              }, '不通过') : '',
            ]);
          }
        }
      ],
      data: [],
      loading:false,
      statuses:{},
      page:1,
      pageSize:10,
      total:0,
      verifyStatus:{},
    }
  },
  created(){
    this.getList();
  },
  methods:{
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
      };
      this.api.dataset.datasetCategoryVerifyList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);

        this.statuses = res.statuses;
        this.verifyStatus = res.verify_statuses;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    allData(data){
      this.$router.push({
        path:'/dataset/category-data-verify',
        query:{
          categoryId:data.id,
        }
      })
    },
    verifyCategory(data,status){ //通过
      let params = {
        category_id:data.id,
        verify_status:status
      };
      this.api.dataset.datasetCategoryVerify(params).then((res)=>{
        this.$Message.success('操作成功');
        this.getList();
      }).catch((err)=>{
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped lang="scss">
.category-verify{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .page{
    margin:20px 0;
    text-align: right;
  }
}
</style>
